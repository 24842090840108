import type { ParentProps } from "solid-js";
import { Box } from "#style/jsx";
import { css } from "#style/css";

import { NavBar } from "#root/components/NavBar";
import { Footer } from "#root/components/Footer";

import { SectionContainer } from "#root/components/SectionContainer";

const pageStyle = css({
  maxWidth: "contentWidth",
  color: "darkGray",
  textStyle: "small",
  mx: "auto",
  minH: "100vh",

  "& h1": {
    textStyle: "heading1",
    mb: "10",
  },
  "& h2": {
    textStyle: "heading2",
    mt: "16",
    mb: "8",
  },
  "& h3": {
    textStyle: "heading3",
    mt: "12",
    mb: "6",
  },
  "& h4": {
    textStyle: "heading4",
    mt: "8",
    mb: "4",
  },

  "& ul": {
    listStyle: "disc inside",
    mt: "4",
    mb: "8",
  },

  "& p": {
    mb: "8",
  },

  "& a": {
    textDecoration: "underline",
  },
});

export type PageLayoutProps = ParentProps<{}>;

export function PageLayout(props: PageLayoutProps) {
  return (
    <>
      <Box px="8">
        <NavBar />
      </Box>
      <SectionContainer as="main">
        <Box class={pageStyle}>{props.children}</Box>
      </SectionContainer>
      <Footer />
    </>
  );
}
